
import { defineComponent } from "vue";

export default defineComponent({
  name: "VideosMaterials",
  setup() {

    const list = [
      {
        title: `Benefícios de la lactosa`,
        file: `beneficios-de-la-lactosa-consumidores.mp4`,
        imageUrl: `/img/videos-materials/video-preview-b.png`,
      },
      {
        title: `¿Como puedo saber si mi bebé es intolerante a la lactosa?`,
        file: `video-lactosa-para-consumidores-pisa.mp4`,
        imageUrl: `/img/videos-materials/video-preview-c.png`,
      },
    ];

    const thumbClick = (idx: number) => {
      const item = list[idx];
      const imageRepo = 'https://api.metacriacoes.com/friesland-campina/app-friso/v1/?action=getFile&type=vid&file=materials/';
      const fileUrl = imageRepo + item['file'];
      window.open( fileUrl );
    }

    return {
      list,
      thumbClick,
    }
  }
});
